/**
 * Basic typography style for copy text
 */
body {
    color: $text-color;
    font: normal 125% / 1.4 $text-font-stack;
}

.trainer-name {
    @extend %text-truncation;
}
