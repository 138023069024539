.match-cards {
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: space-around;
}

.spoiler-button {
    display: flex;
    justify-content: right;
    padding: 10px;
}

.no-match-text {
    @extend %container;
    text-align: center;
}