.account-info-body {
    margin: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: stretch;
    text-align: center;

    .tile-container {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-around;

        div {
            flex: 1 0 0;
            padding-bottom: 1em;
        }
    }

    span {
        margin: 10px;
    }

}