.amiibo-card {
    @extend %card;
    display: grid;
    grid-template: 
        "header header" 50px
        "picture stats" 150px
        "footer footer" 50px
        / 150px 200px;
    
    grid-row-gap: 10px;


    padding-bottom: 10px;

    header {

        //text-align: center;
        font-size: 1.3rem;

        box-shadow: -5px -5px 10px 0px rgba(0, 0, 0, 0.5) inset;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    footer {
        display: flex;
        justify-content: space-around;
    }

    img {
        grid-area: picture;
        height: 100%;
        width: 100%;
        object-fit: contain;
        place-self: center;
        margin-left: 10px;
    }

    .html2canvas img {
        height: 100%;
        width: unset;
    }

    ul {
        grid-area: stats;
        font-size: 1rem;
    }

    span {
        margin-left: 20px;
    }

    svg {
        margin-right: 10px;
        cursor: pointer;
        //position: absolute;
    }

}
