.tier-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    margin: auto;
    position: relative;

    .watermark {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 10px;
        font-weight: 600;
        text-align: right;
    }

    .tier {
        display: flex;
        width: 80vw;

        .character-list {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .char-image {
            height: 45px;
            width: 45px;
        }

        .character {
            margin: 3px;
            padding: 4px;
            font-size: .9em;
            text-align: center;
            height: 85px;
            z-index: 1; /* So watermark doesn't cover character on low width devices */
        }

        .tier-letter {
            height: 85px;
            width: 40px;
            margin: 0 0 0 20px;
            display: flex;
            justify-content: left;
            align-items: center;
        }
    }
}

.tier-list-header {
    margin: 12px auto 12px auto;
    padding: 10px;
    width: 80vw;
    text-align: center;
    
    select, button {
        margin: 10px;
    }
}

