.leaderboard-card {
    display: flex;
    flex-direction: column;
    width: 75vw;
    min-width: 350px;
    
    &:hover {
        cursor: pointer
    }

    .card-main {
        display: flex;
        justify-content: center;
        align-items: stretch;
        /*Undo global button styling*/
        background-color: transparent;
        color: white;
        padding: 0px;
        font-size: inherit;
        font-weight: inherit;

        .card-left {
            flex: 65 1 0;
            overflow: hidden;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                flex: 70 1 0;
                overflow: hidden;
                display: flex;
                flex-direction: column;
            }

            h1 {
                margin-right: 5px;
                flex: 10 0 0;
            }
        }
    
        .card-right {
            flex: 35 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .card-extra {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-around;

        .card-extra-info {
            margin: 8px;

            .card-extra-name {
                font-size: 1rem;
            }
    
            .card-extra-value {
                font-size: 1.5rem;
                text-align: center;
            }
        }
    }

    .card-stock-icon {
        height: 60px;
        width: 60px;
        margin-right: 5px;
    }

    h1, h2, h3 {
        margin: 0;
    }

    @media only screen and (max-width: 600px) {
        h1 {
          font-size: 1rem;
        }

        .card-stock-icon {
            height: 45px;
            width: 45px;
        }
    }
}