.faq-body {
    margin: 20px;
    padding: 10px;

    h3 {
        margin-top: 0;
    }

    p {
        margin-left: 1em;
        margin-bottom: 1.5em;
    }
}