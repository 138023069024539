// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.account-info-body {
    @extend %container;

    .tile-container {
        div {
        border: solid 1px rgba(255,255,255,0.5);
        }
    }
}

.add-card {
    @extend %card;

    header {
        background-color: $monochromatic-medium;
        font-weight: 500;
        border-radius: 10px 10px 0 0;
    }
}

.amiibo-card{
    @extend %card;

    header {
        background-color: $monochromatic-medium;
        font-weight: 600;
        border-radius: 10px 10px 0 0;
    }

    img {
        filter: grayscale(100%);
    }
}

.amiibo-card.standby{
    $standby-purple: rgb(100, 0, 160);
    box-shadow: 0 0 15px 3px rgba($standby-purple, 0.5);

    header {
        background-color: rgba($standby-purple, 0.75);
    }

    img {
        filter: initial;
    }
}

.amiibo-card.active{

    box-shadow: 0 0 15px 3px rgba($logo-dark-green, 0.5);

    header {
        background-color: $brand-color;
    }

    img {
        filter: initial;
    }
}

.amiibos-page{
    .no-amiibo{
        @extend %container;
    }
}

.App {
    background-image: url("../../images/mainLogoWhite.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-Height: 100vh;
}

body {
    margin: 0;
    background-color: $monochromatic-darkest;
}

button {
    @extend %input;
}

.chart-filter {
    input {
        @extend %input;

        font-size: .75em;
    }
}

%container {
    color: white;
}

.faq-body {
    @extend %container;
}

.file-upload-card {

    header {
        background-color: $logo-blue;
        color: $monochromatic-darkest;
    }
}

.header {
    background-color: $monochromatic-dark;
    box-shadow: 0 0 10px 1px rgba(255,255,255,0.7);
    margin-bottom: 20px;
}

.home-page {
    @extend %container;
}

.leaderboard-card {

    @extend %container;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid black;

    .card-main {
        background: $monochromatic-medium;
        border-radius: 0px;
    }

    /* Border makes card-right appear as a unique polygon */
    .card-right{
        clip-path: polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%);
        background-color: $other-place;
    }

    .card-right.first {
        @extend %has_shine_animation;
        background: metallic-gradient($first-place);
        color: scale-color($color: $first-place, $lightness: -50%);
        text-shadow: 0px 2px 1px scale-color($color: $first-place, $lightness: 50%);
        box-shadow: inset -4px 4px 3px scale-color($color: $first-place, $lightness: 50%);
    }

    .card-right.second {
        @extend %has_shine_animation;
        background: metallic-gradient($second-place);
        color: scale-color($color: $second-place, $lightness: -50%);
        text-shadow: 0px 2px 1px scale-color($color: $second-place, $lightness: 50%);
        box-shadow: inset -4px 4px 3px scale-color($color: $second-place, $lightness: 50%);
    }

    .card-right.third {
        @extend %has_shine_animation;
        background: metallic-gradient($third-place);
        color: scale-color($color: $third-place, $lightness: -50%);
        text-shadow: 0px 2px 1px scale-color($color: $third-place, $lightness: 50%);
        box-shadow: inset -4px 4px 3px scale-color($color: $third-place, $lightness: 20%);
    }
    .card-right.top-ten {
        background: metallic-gradient($top-ten-place, -20, 5, 90deg, 0.6), url("../../images/stone.webp");
        color: scale-color($color: $top-ten-place, $lightness: -50%);
        text-shadow: 0px 2px 1px scale-color($color: $top-ten-place, $lightness: 50%);
        box-shadow: inset -4px 4px 3px scale-color($color: rgba($top-ten-place, 0.7), $lightness: 50%);
    }
/*
    .card-extra {
        border-top: 5px solid $other-place;
    }

    .card-extra.first, .card-extra.second, .card-extra.third, .card-extra.top-ten {
        border-top: 5px solid;
        border-image-slice: 1;
    }

    .card-extra.first {
        border-image-source: metallic-gradient($first-place);
    }

    .card-extra.second {
        border-image-source: metallic-gradient($second-place);
    }

    .card-extra.third {
        border-image-source: metallic-gradient($third-place);
    }

    .card-extra.top-ten {
        border-image-source: metallic-gradient($top-ten-place, -20, 5, 90deg, 0.6), url("../../images/stone.webp");
    }*/
}

.match-card {
    @extend %card;

    .card-body.winner {
        border-top: solid 1.5px rgba($logo-dark-green, 1);
        border-bottom: solid 1.5px rgba($logo-dark-green, 1);
    }

    .card-body.loser {
        border-top: solid 1.5px rgba($logo-red, 1);
        border-bottom: solid 1.5px rgba($logo-red, 1);
    }
}

.match-card.winner, .mini-match-card .winner, .mini-match-card.winner {
    box-shadow: 0 0 15px 3px rgba($logo-dark-green, 0.5);
}

.match-card.loser, .mini-match-card .loser, .mini-match-card.loser {
    box-shadow: 0 0 15px 3px rgba($logo-red, 0.5);
}

.mini-match-card .circle {
    background-color: $monochromatic-dark;
}

.navigation {
    .nav-select {
        @extend %input;
    }
}

.popUp-main {
    @extend %container;
}

.tag {
    background-color: $logo-blue;
    a {
        color: black;
    }
}

.tier-list {
    .tier {
        background-color: rgba($monochromatic-dark, .95);
        border: 2px black solid;
    }
    .character {
        background-color: $monochromatic-darkest;
        border: 1px rgba(white, .4) solid;
    }
    .character.high-variance {
        border: 2px rgba(rgb(163, 146, 7), 1) solid;
    }
    .character-list {
        background-color: rgba($monochromatic-darkest, .5);
    }
}

.tier-list-header {
    @extend %container;

    select {
        @extend %input;
    }
}