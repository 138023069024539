.mainLogo {
    max-height: 90%;
    max-width: 90%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.home-page{
    margin: 20px;
    padding: 10px;

    h3 {
        margin-top: 0;
    }

    p {
        text-indent: 20px;
    }
}
