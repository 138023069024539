.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding: 5px 10px;


    .button-twitch {
        background-color: #9147ff;
        color: $text-color;
        &:hover{
            background-color: #772ce8;
        }
    }
}