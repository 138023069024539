.drop-down-menu {
    min-width: 8rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
    background-color: rgba($monochromatic-lightest, 0.8);
    color: $monochromatic-darkest;
    position: absolute;

    ul {
        list-style-type: none;
        font-size: 0.8rem;
        padding: 0;
        flex: 1 0 0;
        text-align: center;
        font-weight: 600;
    }

    li {
        cursor: pointer;
        padding: 0.5rem;
    }

    li:hover {
        background-color: $monochromatic-light;
    }

    z-index: 2;
}

.html2canvas .drop-down-menu {
    display: none;
}