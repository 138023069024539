.match-card {
    @extend %card;
    display: flex;
    flex-direction: column;

    width: 340px;
    height: 260px;
    margin: 12px;
    margin-bottom: 20px;

    .card-header {
        position: relative;
        flex: 20 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    a, span {
        flex: 1;
        text-align: center;
    }

    .card-body {
        position: relative;
        flex: 65 0 0;
        display: flex;
        justify-content: center;
        border-top: solid 1.5px lightgray;
        border-bottom: solid 1.5px lightgray;
    }

    .card-body-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1 0;
        padding: 5px;
        overflow: hidden;
    }

    .card-footer {
        position: relative;
        flex: 15 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .stock-icon {
        height: 60px;
        width: 60px;
    }

    .ruleset {
        font-size: 0.85em;
    }
}