.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .nav-item {
        margin: 0 0.25em;
        padding: 0 0.25em;
        font-weight: 600;
        &.active, &:hover {
            a {
                color: white;
                text-decoration: underline;
            }
        }

        a {
            color: #aaa;
            text-decoration: none;
            &:hover, &:active, &:focus {
                text-decoration: underline;
            }
        }
    }
}