.search-parameters-card {
    @extend %card;
    background-color: initial;
    box-shadow: initial;
    border-radius: initial;
    grid-gap: 10px;

    .submenu {
        
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin: 0 5%;
        padding: 0.5rem;
        background-color: rgba($monochromatic-lightest, 0.8);
        color: $monochromatic-darkest;
        
        & > * {
            margin: 0.5rem;
        }
    }

    & > header {
        @extend %container;
        z-index: 1;
        padding: initial;
        position: sticky;
        top: 0;

        min-width: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;

        .menu-item {
            max-width: max-content;
            flex: 1 1 0;
            cursor: default;
            padding: 0 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: clip;

            svg {
                margin-right: 0.5rem;
            }

        }

        .menu-item:hover {
            background-color: rgba($monochromatic-lightest, 0.8);
            color: $monochromatic-darkest;
        }

        .menu-item:hover .submenu, .submenu:focus-within {
            display: flex;
            flex-flow: row wrap;
            align-items: flex-start;
        }
    }
}

.sorting-form {
    @extend %container;
    display: flex;
    flex-flow: column nowrap;
    font-size: 1.1rem;
    
    select, button {
        margin: 0.5rem;
    }
}
