.amiibos-page {    

    margin: 10px;

    .search-parameters-card.amiibo-cards {

        section {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
            justify-content: space-around;
        }
        
        .amiibo-card{
            margin: 10px;
        }
    }
    

    .no-amiibo {
        padding: 10px
    }
}

.ruleset-name {
    font-size: 0.7em;
}

.add-amiibo-form {
    @extend %container;
    padding: 1em;

    display: flex;
    flex-flow: column nowrap;

    & > * {
        margin: 0 0.7em 0.7em 0.7em;
    }
}

.amiibo-restrictions {
    @extend %container;
    padding: 1rem;

    pre {
        margin: 0;
    }
}