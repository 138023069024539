.mini-match-card {
    @extend %card;

    --radius: 45px;

    width: 245px;
    height: 90px;
    margin: 18px;
    display: grid;
    grid-template-columns: var(--radius) 130px 70px;
    margin-left: var(--radius);

    .circle-text {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 1.25em;
        font-weight: bold;
    }

    .mini-body {
        text-align: center;
        margin-top: auto;
        margin-bottom: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .mini-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mini-stock-icon {
        height: 50px;
        width: 50px;
    }

    .mini-trainer-info {
        font-size: .9em;
        font-weight: 600;
    }

    .mini-trainer-name {
        font-size: .85em;
    }

    .mini-amiibo-name {
        font-size: 1.10em;
    }

    .circle {
        height: calc(var(--radius) * 2);
        width: calc(var(--radius) * 2);
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        margin-left: calc(var(--radius) * -1);
    }
}