.leaderboard-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;

    select {
        @extend %input;
        margin: 10px;
    }

    header {
        @extend %container;
        width: 75vw;
        min-width: 350px;
        margin: 12px;
        padding: 5px 5px 8px 5px;
    }

    hr {
        opacity: 50%;
    }

    .empty-message {
        @extend %container;
        padding: 5px;
    }
}