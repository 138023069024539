// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

@keyframes shine{
    20% {
        opacity: 1;
        left: 100%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    21% {
        opacity: 0;
        left: 100%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    100% {
        opacity: 0;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

/**
 * Set up a decent box model on the root element
 */
html {
    box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
    box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
    color: $logo-blue;
    text-decoration: underline;

    @include on-event {
        color: $logo-red;
    }

    svg {
        cursor: pointer;
    }
}

button, select {
    background-color: $logo-blue;
    color: $monochromatic-darkest;
    font-size: 1rem;
}

button.confirmation {
    background-color: $logo-dark-green;
}

button.cancellation {
    background-color: $logo-red;
}

button:disabled {
    opacity: 50%;
}

.cursorPointer {
    cursor: pointer;
}

.cursor {
    cursor: pointer;
}

/**
 * Base classes for other things to inherit
 */

%container {
    background-color: rgba($monochromatic-dark, 0.95);
    box-shadow: 0 0 15px 3px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

%card {
    @extend %container;

    display: grid;
    grid-template: 
        "header" 
        "body"  
        "footer" 
        / 1fr;
    
    & > header {
        grid-area: header;
        padding: 0.5em;
    }

    & > section {
        grid-area: body;
        padding: 0.5em;
    }

    & > footer {
        grid-area: footer;
        padding: 0.5em;
    }

    
    
}

%input {
    border: none;
    border-radius: 4px;
    font-weight: 600;
    padding: 5px;
    &:hover {
        cursor: pointer;
    }
}

%has_shine_animation {
    overflow: hidden;
    position: relative;
}

%has_shine_animation::after{
    animation: shine 5s ease-in-out infinite;
    animation-fill-mode: forwards;
    content: "";
    position: absolute;
    left: -100%;
    width: 100%;
    padding-top: 100%;
    opacity: 0;
    transform: rotate(45deg);
    background: linear-gradient(
        90deg,
        rgba(255,255,255, 0) 0%,
        rgba(255,255,255, 0) 20%,
        rgba(255,255,255, 0.13) 40%,
        rgba(255,255,255, 0.8) 50%,
        rgba(255,255,255, 0) 60%,
        rgba(255,255,255, 0) 100%,
    );
}

.tag-container {
    display:flex;
    flex-flow: row wrap;
    align-items: flex-start;
}

.tag {
    font-size: 1rem;
    border-radius: 10px;
    padding: 0.25em;
    margin: 0.25em;
    max-width: max-content;
}

.html2canvas, .html2canvas * {
    box-shadow: unset !important;
}

%text-truncation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}