.file-upload-card {
    @extend %card;
    max-width: 800px;
    margin: 1em 1.5em;
    float: right;
    font-size: 1rem;

    header {
        font-size: 1.5em;
        font-weight: 600;
        border-radius: 10px 10px 0 0;
    }

    section {
        font-size: 0.99em;
    }

}
