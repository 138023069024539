
.info-organizer {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    width: 100%;
    
    .info-section {
        border: solid white 1px;
        border-radius: 20px;
        padding: 5px;
        margin: 10px auto 10px auto;
    }

    .info-body {
        margin: 10px;
        max-width: 1000px;
        min-width: 200px;
        flex: 1 1 0;
    }

    .info-sidebar {
        @extend .info-section;
        overflow: hidden;
        min-width: 320px;
        max-height: 100%;
        display: flex;
        justify-content: right;
        flex-direction: column;
    }

    .match-card-col {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        width: 320px;
        padding-left: 10px;
    }

    .info-stock-icon {
        height: 80px;
        width: 80px;
    }

    .info-title {
        @extend .info-section;
        display: flex;
        justify-content: space-around;
        text-align: center;

        h2 {
            margin: 0px;
        }
    }

    .info-amiibo-extra {
        @extend .info-section;
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
        text-align: center;

        .info-amiibo-extra-name {
            font-size: 1rem;
            margin: 10px;
        }

        .info-amiibo-extra-value {
            font-size: 1.5rem;
            text-align: center;
        }
    }

    .info-chart {
        @extend .info-section;
    }
}