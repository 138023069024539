.chart {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        text-align: center;
    }

    .chart-filter {
        margin-left: auto; 
        margin-right: 0;
        text-align: center;
    }

    ul {
        text-align: right;
    }
}