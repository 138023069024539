.add-card {
    display: grid;
    grid-template: 
        "title" 50px
        "picture" 150px
        "footer" 25px
        / 350px;
    
    grid-row-gap: 10px;

    margin-bottom: 10px;

    .title {
        grid-area: title;

        text-align: center;

        box-shadow: -5px -5px 10px 0px rgba(0, 0, 0, 0.5) inset
    }

    .footer {
        grid-area: footer;

        text-align: center;
        font-size: 12px;
        color: red;
    }

    svg {
        grid-area: picture;
        max-height: 150px;
        margin-left: auto;
        margin-right: auto;
        cursor: pointer;
    }

    ul {
        grid-area: stats;
        font-size: 16px;
    }

}