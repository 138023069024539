.popUp {
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background-color: rgba(0, 0, 0, 0.75); 
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.popUp-close {
    position: fixed;
    left: 80%;
    right: 5%;
    top: 5%;
    bottom: 90%;
    z-index: 11;

    border: 0;
    background: transparent;
    margin: 0;
    color: gray;
    font-size: 1.7em;
    font-weight: bold;
}

.popUp-main {
    position: fixed;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 11;
}