// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;

/// Copy text color
/// @type Color
$text-color:  rgba(255, 255, 255, 1) !default;

/// The right color green
/// @type Color
$brand-color: rgba(34, 71, 34, 1);

/// Main logo colors
/// @type Color
$logo-dark-green: rgba(0, 150, 130, 1) !default;
$logo-light-green: rgba(165, 200, 16, 1) !default;
$logo-yellow: rgba(245, 170, 0, 1) !default;
$logo-blue: rgba(0, 155, 235, 1) !default;
$logo-red: rgba(230, 0, 30, 1) !default;
$logo-pink: rgba(230, 45, 140, 1) !default;

/// Monochromatic colors
/// @type Color
$monochromatic-lightest: rgb(192, 192, 192) !default;
$monochromatic-light: rgb(128, 128, 128) !default;
$monochromatic-medium: rgb(64, 64, 64) !default;
$monochromatic-dark: rgb(32, 32, 32) !default;
$monochromatic-darkest: rgb(0, 0, 0) !default;

/// Leaderboard Color Variables
/// @type Color
$first-place: hsl(46, 65%, 52%);
$second-place: hsl(255, 2%, 67%);
$third-place: #cf7d52;//hsl(45, 26%, 34%);
$top-ten-place: hsl(45, 5%, 55%);
$other-place: $monochromatic-dark;

/// Container's maximum width
/// @type Length
$max-width: 1180px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
    'small': 320px,
    'medium': 768px,
    'large': 1024px,
) !default;

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/build/' !default;
