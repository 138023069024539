.alert-box {
    @extend %card;

    margin: 0.5rem;
    pointer-events: auto;

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            margin-left: 1em;
        }
    }

    footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

.alert-box.error {
    
    box-shadow: 0 0 15px 3px rgba($logo-red,  0.5);

    header {
        color: $logo-red
    }
}

.alert-box.success {

    box-shadow: 0 0 15px 3px rgba($logo-dark-green, 0.5);

    header {
        color: $logo-dark-green;
    }
}

.alert-box.warning {

    box-shadow: 0 0 15px 3px rgba($logo-yellow, 0.5);

    header {
        color: $logo-yellow;
    }
}

.alerts-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50%;
    pointer-events: none;
    z-index: 20;
}
